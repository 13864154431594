import React from 'react'

export default function Services() {
    return (
        <div>
            <section id="services" className="services section-bg">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Services</h2>
                        <p style={{ "color": "white" }}>At DijaVu-DI we provide a range of services to help our client in all phases of their product development,
                            from thoughts and basic ideas all the way to a scalable code in production.</p>
                    </div>
                    <div className="row">
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>CTO Services</a></h4>
                                <p>We provid CTO services and C-Level technical advisory in the fields of:</p>
                                <ul>
                                    <li>
                                        Software architecture and development
                                    </li>
                                    <li>
                                        Machine learning and Analytics
                                    </li>
                                    <li>
                                    	Risk management
                                    </li>
                                    <li>
                                        Hiring the right tech talents for your company
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Design Sprints</a></h4>
                                <p>We help you formulate your innovative ideas or new features and modules into UX designs and requirements</p>
                                <ul>
                                    <li>
                                        We conduct research for best UX experience and flow.
                                    </li>
                                    <li>
                                        We suggest creative designs.
                                    </li>
                                    <li>
                                        We build wireframes and prototypes.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>MVPs</a></h4>
                                <p>We help you building MPVs during the incubation phase to enable you to bring innovative ideas or new features to
                                    market clarification and expert validation fast and effective.</p>
                                <ul>
                                    <li>
                                    We develop MVPs with scaleup and production in mind.
                                    </li>
                                    <li>
                                    We implement only modular and extendable MVPs with clean code.
                                    </li>
                                    <li> We do not believe  quality is
                                    a trade-off with agility</li>
                                </ul>                               
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Scaleup Phase</a></h4>
                                <p>We help you in the scale up phase from MVP to concrete and fully functional
                                   features deployed on premises or to the cloud </p>
                                <ul>
                                    <li>
                                    We help you setting up your cloud infrastructure and dev, staging and production environments.
                                    </li>
                                    <li>
                                    We help with setup of CI/CD pipelines and DevOps procedure.
                                    </li>
                                    <li>
                                    We help improving your scalability and boosting performance.
                                    </li>
                                </ul>                               
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Sustainable Development</a></h4>
                                <p>We help you bring your scaled up software to production </p>
                                <ul>
                                    <li>
                                    We help you bring your scaled up software to production
                                    </li>
                                    <li>
                                        We support you in maintaining code in production.
                                    </li>
                                    <li>
                                      We back you up with extending existing or adding new features.
                                    </li>
                                </ul>                               
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Machine Learning</a></h4>
                                <p>We have strong expertise in house and through our partners in 
                                    the field of machine learning, analytics and risk management</p>
                                <ul>
                                    <li>
                                    We can help you harness the power of the knowledge contained in your data
                                    </li>
                                    <li>
                                        We support you in prototyping models that can generalize, validate them and document uncertainty 
                                        and assumptions and model limitations
                                    </li>
                                    <li>
                                      We help you bring your code into scaleup and production rabidly and efficiently.
                                    </li>
                                </ul>                               
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Agile Coaching & QA</a></h4>
                                <ul>
                                    <li>
                                    We help you establish inhouse agile and quality assurance best practices and procedures
                                    </li>
                                    <li>
                                        We provide auditing services for your existing agile and quality assurance procedures.
                                    </li>
                                    <li>
                                      We tune and tailor your procedure and methodology for efficiency and assurance.
                                    </li>
                                </ul>                               
                            </div>
                        </div>
                        <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
                            <div className="icon-box">
                                <div className="icon"></div>
                                <h4><a href>Incubation-Partnership Program</a></h4> 
                             <ul>
                                    <li>
                                    For limited innovative business ideas with high potential, we offer a deduction of 
                                  the service cost for shares and partnership
                                    </li>
                                    <li>
                                        We support charity organization that help sustainability and development of the local communities and services
                                         in Egypt, through our spectrum of services.
                                    </li>
                                    <li>
                                        To check if your project qualifies, please contact us!
                                    </li>
                                </ul>    
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
