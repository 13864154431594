import React from 'react'

export default function GetStarted() {
    return (
        <div>
            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Get Started</h2>
                    </div>
                    <div className="row content" style={{ "text-align": "center;"}}>
                        <div className="col-lg-12">
                            <ul>
                                <li>
                                    If you are interested in one of our services, please give us a call or drop us an email. One of our business
                                    development team members will set up a meeting to understand your project need and to discuss if our company is the
                                    right option for you.                                </li>
                                <li> Once we understand your needs on business level, we will contact you for a technical requirements elicitation and discuss different
                                    possible offerings depend on the technical choices.</li>
                                <li> Soon after the high-level requirements is communicated we will come up with a quote and
                                    whether we would like to offer an incubation for your project for shares.
                                </li>
                                <li>If you are a start up with a limited funding, we will be able at this stage to decide if we can offer you an incubation for your
                                    project for shares (we deduct a percentage of the cost for shares, the percentage can be 25%, 50% and 75% depends on the novelty of the idea and our team availability.
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 d-flex align-items-stretch">
                            <div className="info">
                                <div className="address">
                                    <i className="bi bi-geo-alt" />
                                    <h4>Location:</h4>
                                    <p>El Hegaz st, Cairo, Cairo 11713, Egypt</p>
                                </div>
                                <div className="email">
                                    <i className="bi bi-envelope" />
                                    <h4>Email:</h4>
                                    <p>drmoe@esmatdigitalincubator.com</p>
                                </div>
                                <div className="phone">
                                    <i className="bi bi-phone" />
                                    <h4>Call:</h4>
                                    <p>Please contact us by email, and we will come back to you at the earliest convenience.</p>
                                </div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3451.5629796133358!2d31.333170515489392!3d30.106700022583123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14581587dac6165b%3A0x2f984c682d69cc4c!2sEl%20Hegaz%20St%2C%20Cairo%20Governorate%2C%20Egypt!5e0!3m2!1sen!2sno!4v1627836284328!5m2!1sen!2sno" frameBorder={0} style={{ border: 0, width: '100%', height: 290 }} allowFullScreen />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
