import React, { Component, PureComponent } from 'react'

export default class NavWrap extends Component {

    constructor(props) {
        super(props)
        this.state = { mobileIsActive: false }
    }

    toggleClicked = () => {
        this.setState({ mobileIsActive: !this.state.mobileIsActive })
    }

    navClicked = () => {
        if (this.state.mobileIsActive)
            this.toggleClicked()
        return
    }
    select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    infiniteScroll = () => {
        let navbarlinks = this.select('#navbar .scrollto', true)
        let position = window.scrollY + 200
        navbarlinks.forEach(navbarlink => {
            if (!navbarlink.hash) return
            let section = this.select(navbarlink.hash)
            if (!section) return
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                navbarlink.classList.add('active')
            } else {
                navbarlink.classList.remove('active')
            }
        })
    }

    componentDidMount() {
        window.addEventListener('scroll', this.infiniteScroll);
    }
    render() {
        return (
            <div>
                <nav id="navbar" className={this.state.mobileIsActive ? "navbar navbar-mobile" : "navbar "}>
                    <ul>
                        <li onClick={this.navClicked.bind(this)}><a className="nav-link scrollto active" href="#hero">Home</a></li>
                        <li onClick={this.navClicked.bind(this)}><a className="nav-link scrollto" href="#about">About</a></li>
                        <li onClick={this.navClicked.bind(this)}><a className="nav-link scrollto" href="#services">Services</a></li>                        
                        <li onClick={this.navClicked.bind(this)}><a class="nav-link scrollto" href="#career">Career</a></li>


                        {/*                         <li onClick={this.toggleClicked.bind(this)}><a className="nav-link scrollto" href="#portfolio" >Portfolio</a></li>
 */}
                        <li onClick={this.navClicked.bind(this)}><a class="getstarted scrollto" href="#contact">Get Started</a></li>                 </ul>
                    <i className={this.state.mobileIsActive ? "bi mobile-nav-toggle bi-x" : "bi mobile-nav-toggle bi-list"}
                        onClick={this.toggleClicked.bind(this)} />
                </nav>
            </div>
        )
    }
}

