import React from 'react'

export default function Career() {
    return (
        <div>

            <section id="career" className="about">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Career</h2>
                    </div>
                    <div>
                        <p>

                        </p>
                        <p>We are continuously looking for talents that shares our vision of improving
                        safety, welfare, and wellbeing of mankind. We do that by enabling innovation through digital technology.
                            We build sophisticated and challenging software applications
                            and you will have an exiting time with us. On the journey there will be a lot of fun and learning.

                        </p>
                        We aim at creating human centered and innovative work culture. Moreover, we empower our staff to take the initiatives and
                        leading roles in the journey. We embrace autonomy as long as we are keeping an eye on the goals and staying aligned.
                        One of our key values is to enable our staff to grow both personally and technically.
                        <p>
                            <p>
                                We build our
                                values around respect to each other and to our clients and partners.
                            </p>

                        </p>
                        <p>
                            We are growing our team in Cairo and we are looking for graduate developers and UX designers.
                        </p>
                        <p>
                            If our vision excite you and you want to make a difference and grow both technically and personally with us,
                            please send us an application. It should include a CV, Cover letter/letter of interest, recommendation letters
                            and at least two references to contact. The application should be in one pdf file sent to Dr.Eng. Mohamed Ibrahim, 
                            drmoe@esmatdigitalincubator.com. Don't hesitate to contact Dr. Mohamed Ibrahim if you have questions in regards of
                             the role and application.
                             Please respect our minimum requirements for the position.
                        </p>
                    </div>
                    <div className="section-title">
                        <h4>Minimum Qualifications</h4>
                    </div>
                    <div className="row content" >
                        <div className="col-lg-12">
                            <ul>
                                <li><i className="ri-check-double-line" />
                                    B.Sc. or M.Sc. in computer science/engineering or Information technology.
                                </li>
                                <li><i className="ri-check-double-line" />Public university graduates with minimum overall grade of C-good.
                                    Candidates' with exceptional grades from private institutions will be considered.
                                </li>
                                <li><i className="ri-check-double-line" /> 0-2 years experience in the relevant technology stacks!
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="section-title">
                        <h4>Graduate Software Developer</h4>
                    </div>
                    <div className="row content" >
                        <div className="col-lg-12">
                            <ul>
                                <li><i className="ri-check-double-line" />
                                    C# .Net.Core, SQL, Entity Framework, Nuget, Azure, Azure DevOps, CI/CD, Git,
                                    React, Redux, Angular, JS, HTML, webpack, npm, scaffolding, VS code.
                                </li>
                                <li><i className="ri-check-double-line" />
                                    Experience in unit, integration and regression testing and take testing seriously.</li>
                                <li><i className="ri-check-double-line" /> Clean coding, design patterns and coding best practices.</li>
                                <li><i className="ri-check-double-line" /> Knowledge in algorithms, data structure and agile methodologies
                                    is a plus.</li>
                                <li><i className="ri-check-double-line" /> Knowledge in data analytics stack is a plus,
                                    TensorFlow, numpy, panda, etc.</li>
                                <li><i className="ri-check-double-line" /> Structured and good technical writing skills
                                    and enjoy documentation.</li>
                                <li><i className="ri-check-double-line" /> Good English communication skills</li>
                            </ul>
                        </div>
                    </div>
                    <div className="section-title">
                        <h4>UX/UI</h4>
                    </div>
                    <div className="row content">
                        <div className="col-lg-12">
                            <ul>
                                <li><i className="ri-check-double-line" />Figma, UXPin, Adobe XD, Sketch,
                                    or similar. WordPress, CSS, HTML and Sass.
                                </li>
                                <li><i className="ri-check-double-line" /> Excellent command of English.</li>
                                <li><i className="ri-check-double-line" /> Photography, content editing and graphics design is an advantage.
                                </li>
                                <li><i className="ri-check-double-line" />Ability to code UI is a big plus.
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>

            </section>

        </div>
    )
}
