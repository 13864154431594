import React from 'react'

export default function Hero() {
    return (
        <div>
            <section id="hero" className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay={200}>
                            <h1>Digital Incubator and Accelerator For Your Business</h1>
                            <h2>We are highly skilled multidisciplinary and cross functional tech company specialized in digital 
                                solutions for bussines</h2>
                            <h2>We have strong expertise in development of SaaS for modeling and simulation, 
                                risk, analytics and machine learning applications</h2>

                            <div className="d-flex justify-content-center justify-content-lg-start">
                                <a href="#contact" className="btn-get-started scrollto">Get Started</a>
                                {/*                                 <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video"><i className="bi bi-play-circle" /><span>Watch Video</span></a>
*/}
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay={200}>
                            <img src="assets/img/hero-img.png" className="img-fluid animated" alt />
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}
